<template>
    <div class="mx-1 md:mx-8 my-8">
        <div class="flex mb-5">
            <h1 class="text-2xl mt-2 ml-2">
                Usuários / {{ $route.params.id ? 'Editar' : 'Novo' }}
            </h1>
        </div>
        
        <div class="mt-5 md:mt-0 md:col-span-2">
            <form action="#" method="POST" autocomplete="off">
                <div class="px-5 pb-4">
                    <div class="grid grid-cols-12 gap-6">

                        <div v-if="$store.state.user.root" class="col-span-12 md:col-span-12">
                            <label for="empresa" class="block text-sm font-medium">Empresa</label>
                            <select v-model="form.empresa" id="empresa" name="empresa" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <option v-for="e in empresas" :key="e._id" :value="e._id">{{e.nome}}</option>
                            </select>
                        </div>

                        <div class="col-span-12 md:col-span-6">
                            <label for="name" class="block text-sm font-medium">Nome</label>
                            <input v-model="form.name" type="text" name="name" id="name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div>

                        <div class="col-span-12 md:col-span-6">
                            <label for="email" class="block text-sm font-medium">Email</label>
                            <input v-model="form.email" type="text" name="email" id="email" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div> 
                        
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-3">
                        <div class="col-span-12 md:col-span-12">
                            <div class="mt-2 mb-2">
                                <label class="inline-flex items-center">
                                    <input type="radio" class="form-radio" name="typeUser" v-model="form.typeUser" value="normal">
                                    <span class="ml-2">Normal</span>
                                </label>
                                <label class="inline-flex items-center ml-6">
                                    <input type="radio" class="form-radio" name="typeUser" v-model="form.typeUser" value="admin">
                                    <span class="ml-2">Admin</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-6 mt-3">
                        <div v-if="!alterarPassword" class="col-span-12 md:col-span-4">
                            <button @click="alterarPassword = !alterarPassword" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-500">
                                Alterar password
                            </button>
                        </div>
                    </div>
                    <div v-if="alterarPassword" class="grid grid-cols-12 gap-6 mt-3">
                        <div class="col-span-12 md:col-span-12">
                            <label for="password" class="block text-sm font-medium">Senha</label>
                            <input autocomplete="new-password" v-model="form.password" type="password" name="password" id="password" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div>
                        <div class="col-span-12 md:col-span-12">
                            <label for="password2" class="block text-sm font-medium">Confirme a senha</label>
                            <input autocomplete="new-password" v-model="form.password2" type="password" name="password2" id="password2" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                        </div>
                    </div>
                </div>
                
                <div class="grid grid-cols-2 grap-6">
                    <div class="px-5 py-3 text-left sm:px-6">
                        <router-link :to="`/${route}`" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                            Voltar
                        </router-link>
                    </div>
                    <div class="px-5 py-3 text-right sm:px-6">
                        <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400">
                            Salvar
                        </button>
                    </div>
                </div>
                
            </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            empresas: [],
            route: 'users',
            alterarPassword: this.$route.params.id ? false : true,
            form: {
                name: '',
                email: '',
                typeUser: 'normal',
                password: '',
                password2: '',
            }
        }
    },
    methods: {
        async save() {
            this.form.admin = this.form.typeUser === 'admin' ? true : false;
            
            const method = this.form._id ? 'put' : 'post';
            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.push({path: `/${this.route}`});
            } else {
                this.$vToastify.error(req.data.err);
            }
        }
    },
    async beforeMount() {
        const id = this.$route.params.id;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
            if(req.data.admin) this.form.typeUser = 'admin';
            else this.form.typeUser = 'normal';
        }

        const reqEmp = await this.$http.post("/v1/empresas/list");
        this.empresas = reqEmp.data.data;
    },
}
</script>